$(document).on("turbolinks:load", function() {
    LocationSubLocation();
    $(document).on('change', '#location_sub_location', LocationSubLocation);
    function LocationSubLocation() {
      let sub_location = $('#location_sub_location').val();
      if (sub_location === 'exist') {
        $('.location_main_location').removeClass('d-none');
        $('.enable_memorial_video_checkbox').addClass('d-none');
      } else {
        $('.location_main_location').addClass('d-none');
        $('.enable_memorial_video_checkbox').removeClass('d-none');
      }
    }
});

$(document).ready(function() {
  // Assuming your modal has an ID of 'myModal'
  $('#myModal').on('shown.bs.modal', function() {
      // Listen for any change in the checkbox within the modal
      $('#send_feedback_email_checkbox').change(function() {
          // Set the value of the textbox to 0 whenever the checkbox state changes
          $('#feedback_mail_buffer_textbox').val('0');
      });
  });
});
